import { constants } from '@yola/subscription-manager-js';

const {
  SubscriptionTerm: { MONTHLY, ANNUAL },
} = constants;

const useSpecialOffer = ({
  bundledSubscriptionType,
  extraPriceType,
  activeHostingSubscription,
}) => {
  if (!extraPriceType) {
    return null;
  }

  if (bundledSubscriptionType) {
    return {
      bundled: false,
      disabled: true,
    };
  }

  if (!activeHostingSubscription) {
    return {
      bundled: false,
      disabled: false,
    };
  }

  return {
    bundled: activeHostingSubscription.term === MONTHLY, // `bundled` if user has monthly subscription
    disabled: activeHostingSubscription.term === ANNUAL, // `disabled` if user has annual subscription
  };
};

export default useSpecialOffer;
